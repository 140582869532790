.about {
  composes: target-section from global;
  padding-top: 15rem;
  padding-bottom: 9rem;
  background-color: #fff;
  position: relative;
}

.root {
  composes: section from './../Section.module.css';
}

.intro {
  composes: intro from './../Section.module.css';
}

.introContent {
  composes: introcontent from './../Section.module.css';
}

.title {
  composes: title from './../Section.module.css';
}

.subtitle {
  composes: subtitle from './../Section.module.css';
}

.lead {
  composes: lead from './../Section.module.css';
  font-family: 'librebaskerville-regular', serif;
}

.lead a {
  font-family: 'librebaskerville-bold', serif;
}

.columns {
  composes: row from global;
}

.left {
  composes: left from global;
  composes: col-six from global;
  composes: tab-full from global;
  padding-right: 40px;
}

.right {
  composes: right from global;
  composes: col-six from global;
  composes: tab-full from global;
  padding-left: 40px;
}

.columnTitle {
  composes: h03 from global;
}

.markdown blockquote {
  display: none;
}

.markdown blockquote::before {
  display: none;
}

@media only screen and (max-width: 800px) {
  .left {
    padding-right: 0;
  }

  .right {
    padding-left: 0;
  }
}
