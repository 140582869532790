.masonryBrick {
  display: inline-block;
  padding-top: 360px;
  width: 480px;
  position: relative;
  text-align: left;
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.thumb {
  height: 100%;
  width: 100%;
}

/* .thumb img {
  display: block;
  height: 100%;
  width: 100%;
  vertical-align: top;
  transition: all 0.5s ease-in-out;
} */

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.thumbArea {
  height: 100%;
  width: 100%;
  display: block;
  background: transparent no-repeat 50% 50%;
  background-size: cover;
}

.thumb::before {
  display: block;
  background-color: rgba(0, 0, 0, 0.8);
  content: '';
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.caption {
  color: #fff;
  display: none;
  font-family: 'montserrat-regular', sans-serif;
  font-size: 1.4rem;
  left: 0;
  margin: 0;
  padding: 2em 3em 0 3em;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.details {
  composes: smoothscroll from global;
  composes: button from './../Buttons.module.css';
  composes: stroke from './../Buttons.module.css';
  display: block;
  border-color: #fff;
  color: #fff;
  letter-spacing: 0.25rem;
  transition: all 0.5s ease-in-out;
  font-size: 1.2rem;
  height: 4.8rem;
  padding: 0 2rem;
  line-height: calc(4.8rem - 0.6rem);
  margin-top: 1rem;
}

.details:hover,
.details:focus {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.text {
  position: absolute;
  left: 0;
  bottom: 3rem;
  padding: 0 3rem;
  z-index: 2;
}

.title {
  color: #fff;
  font-family: 'montserrat-semibold', sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin: 0;
}

.subtitle {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'montserrat-regular', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.projectLink {
  display: block;
  color: #fff;
  box-shadow: 0 0 0 1px #fff;
  border-radius: 50%;
  height: 4.2rem;
  width: 4.2rem;
  text-align: center;
  z-index: 500;
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.projectLink i {
  font-size: 1.8rem;
  line-height: 4.2rem;
}

.projectLink::before {
  background-color: rgba(255, 255, 255, 0.1);
  content: '';
  display: block;
  height: 1px;
  left: -3rem;
  position: absolute;
  top: 50%;
  width: 3rem;
}

.projectLink:hover,
.projectLink:focus,
.projectLink:active {
  background-color: #fff;
  color: #000;
}

/* on hover */

.masonryBrick:focus .thumb::before,
.masonryBrick:hover .thumb::before {
  opacity: 1;
  visibility: visible;
}

/* .masonryBrick:hover .thumb img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
} */

.masonryBrick:focus .projectLink,
.masonryBrick:hover .projectLink {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.masonryBrick:focus .caption,
.masonryBrick:hover .caption {
  display: block;
}

.slugLink {
  color: rgba(255, 255, 255, 0.5);
}

.slugLink:focus,
.slugLink:hover {
  color: #fff;
}

@media only screen and (max-width: 1500px) {
  .masonryBrick {
    padding-top: 300px;
    width: 400px;
  }
}

@media only screen and (max-width: 1300px) {
  .masonryBrick {
    padding-top: 250px;
    width: 333px;
  }
}

@media only screen and (max-width: 1099px) {
  .masonryBrick:hover .title,
  .masonryBrick:hover .subtitle {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .masonryBrick {
    padding-top: 250px;
    width: 333px;
  }
}
