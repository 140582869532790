.header {
  width: 100%;
  height: 72px;
  background-color: transparent;
  position: absolute;
  top: 0;
  z-index: 500;
}

.offset {
  transform: translate3d(0, -100%, 0);
  transition: all 1s ease-in-out;
}

.scrolling {
  transform: translate3d(0, 0, 0);
}

.sticky {
  position: fixed;
  top: 0;
  background-color: #000 !important;
  opacity: 0;
  visibility: hidden;
  z-index: 600;
}

.sticky.scrolling {
  opacity: 1;
  visibility: visible;
}

.headerLogo {
  display: inline-block;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 501;
}

.headerLogo a {
  display: block;
  margin: 9px 0 0 0;
  padding: 0;
  outline: 0;
  border: none;
  width: 80px;
  height: 27px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* -------------------------------------------------------------------
 * ## mobile menu toggle - (_site-layout.css)
 * ------------------------------------------------------------------- */

.headerMenuToggle {
  display: none;
  position: absolute;
  right: 40px;
  top: 18px;
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-family: 'montserrat-regular', sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1001;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
}

.headerMenuToggle:hover,
.headerMenuToggle:focus {
  color: #fff;
  background: transparent;
  border: none;
}

.headerMenuToggle span {
  display: block;
  width: 24px;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  left: 9px;
  top: 50%;
  right: auto;
  bottom: auto;
  background-color: white;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  font: 0/0 monospace;
  text-shadow: none;
  color: transparent;
}

.headerMenuToggle span::before,
.headerMenuToggle span::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: inherit;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.headerMenuToggle span::before {
  top: -9px;
}

.headerMenuToggle span::after {
  bottom: -9px;
}

.header.sticky .headerMenuToggle {
  top: 16px;
}

.headerMenuToggle.isClicked span {
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.headerMenuToggle.isClicked span::before,
.headerMenuToggle.isClicked span::after {
  background-color: white;
}

.headerMenuToggle.isClicked span::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.headerMenuToggle.isClicked span::after {
  bottom: 0;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* -------------------------------------------------------------------
 * ## navigation - (_site-layout.scss)
 * ------------------------------------------------------------------- */

.headerNavWrap {
  font-family: 'montserrat-bold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  overflow: hidden;
  position: absolute;
  top: 9px;
  right: 50px;
}

.headerNav {
  display: inline-block;
  list-style: none;
  margin: 0;
  height: 72px;
}

.headerNav li {
  display: inline-block;
  padding-left: 0;
  margin-right: 2rem;
}

.headerNav li a {
  display: block;
  line-height: 72px;
  color: #fff;
}

.sticky .headerLogo a {
  margin: 0;
}

.sticky .headerNavWrap {
  top: 0;
}

.headerNav li.current a {
  font-family: 'montserrat-extrabold', sans-serif;
  color: #af2b2b;
}

/* -------------------------------------------------------------------
 * responsive:
 * header
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  .headerLogo {
    left: 40px;
  }

  .headerNavWrap {
    height: 0;
    width: 100%;
    right: auto;
    top: 0;
    left: 0;
    background-color: #000;
    transition: height 0.5s ease-out;
  }

  .headerNavWrap.headerNavOpen {
    height: 425px;
  }

  .headerNavWrap .headerNav {
    display: block;
    height: auto;
    margin: 80px 40px 4.2rem 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
  }

  .headerNavWrap .headerNav li {
    display: block;
    margin: 0;
    padding: 0;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.07);
  }

  .headerNavWrap .headerNav li a {
    padding: 18px 0;
    line-height: 18px;
  }

  .headerMenuToggle {
    display: block;
  }
}

/* -------------------------------------------------------------------
 * make sure the menu is visible on larger screens
 * ------------------------------------------------------------------- */

@media only screen and (min-width: 801px) {
  .headerNavWrap {
    display: block !important;
  }
}
