.background {
  background: url('./../../images/hero-two.jpg') no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position-x: right;
}

.rellax {
  composes: rellax from global;
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
}

:global(.loaded) .rellax {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .background {
    background-image: url(./../../images/hero-two.jpg?size=1200);
  }
}

@media only screen and (max-width: 800px) {
  .background {
    background-image: url(./../../images/hero-two.jpg?size=800);
  }
}

@media only screen and (max-width: 600px) {
  .background {
    background-image: url(./../../images/hero-two.jpg?size=600);
  }
}

@media only screen and (max-width: 400px) {
  .background {
    background-image: url(./../../images/hero-two.jpg?size=400);
  }
}
