.stats {
  text-align: center;
}

.stats::before {
  content: '';
  width: 75%;
  border-top: 1px solid #e5e5e5;
  position: relative;
  top: -1.8rem;
  height: 1px;
  display: block;
  margin: auto;
}

.row {
  composes: row from global;
  composes: block-1-4 from global;
  composes: block-tab-1-2 from global;
  composes: block-mob-full from global;
  margin: 1.6rem 0 0 0;
  padding: 0;
}

.stats .row {
  margin: 0;
}

.column {
  composes: col-block from global;
  background-color: #f1f1f1;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.upsign {
  position: absolute;
  top: 3rem;
  left: 3rem;
  text-align: left;
}

.upsign i {
  font-size: 2.1rem;
  line-height: 1;
  color: #cf3e3e;
  color: #008c2c;
}

.count {
  font-family: 'librebaskerville-bold', serif;
  font-size: 3rem;
  line-height: 1;
  color: #0d0a0b;
  padding-bottom: 1rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.count::after {
  content: '';
  display: block;
  height: 1px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -50px;
}

.title {
  composes: h04 from global;
  font-family: 'montserrat-semibold', sans-serif;
  margin-top: 1.3rem;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.4rem;
  position: relative;
  margin-bottom: 0;
}

.column:hover,
.columnHighlight {
  background: #111;
  background-size: 175px 175px;
  position: relative;
}

.columnHighlight::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: linear-gradient(
    to bottom,
    rgba(13, 10, 11, 0) 0%,
    #0d0a0b 100%
  );
}

.columnHighlight .count,
.column:hover .count {
  color: #fff;
}

.columnHighlight .count::after,
.column:hover .count::after {
  background-color: #862121;
}

.columnHighlight .title,
.column:hover .title {
  color: rgba(255, 255, 255, 0.4);
}

/* -------------------------------------------------------------------
 * responsive:
 * stats
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
  .count {
    font-size: 2.3rem;
  }

  .title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 1000px) {
  .count {
    font-size: 2rem;
  }

  .title {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 800px) {
  .count {
    font-size: 2.8rem;
  }

  .title {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .column {
    padding: 3rem;
  }
}
