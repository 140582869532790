.root {
  background: #000;
  font-family: 'montserrat-regular', sans-serif;
  font-size: 14px;
  padding: 6.6rem 0 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.2);
}

.root a {
  color: #862121;
}

.goTop a,
.goTop a:visited {
  text-decoration: none;
  border: 0 none;
  display: block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  background: black;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.root a:visited {
  color: #862121;
}

.root a:hover,
.root a:focus {
  color: #fff;
}

.top {
  composes: row from global;
}

.topContent {
  composes: col-full from global;
}

.logo {
  display: inline-block;
}

.logoLink {
  display: block;
  width: 130px;
}

.social {
  list-style: none;
  margin-top: 3rem;
  margin-left: 0;
  font-family: 'montserrat-regular', sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.social::after {
  content: '';
  display: table;
  clear: both;
}

.social li {
  display: inline-block;
  padding-left: 0;
  margin-right: 0.9rem;
  line-height: 3.9rem;
  vertical-align: middle;
}

.social li::after {
  content: '|';
  color: rgba(255, 255, 255, 0.15);
  padding-left: 0.9rem;
}

.social li i {
  font-size: 1.7rem;
  line-height: 3rem;
  vertical-align: middle;
}

.social li span {
  margin-left: 0.6rem;
}

.goTop a:hover,
.goTop a:focus {
  color: #fff;
}

.root .social li a {
  color: #fff;
}

.social li:last-child {
  margin-right: 0;
}

.social li:last-child::after {
  display: none;
}

.bottom {
  composes: row from global;
  margin-top: 7.2rem;
  text-align: center;
}

.bottom .copyright span {
  display: inline-block;
}

.bottom .copyright span::after {
  content: '|';
  display: inline-block;
  padding: 0 1rem 0 1.2rem;
  color: rgba(255, 255, 255, 0.1);
}

.bottom .copyright span:last-child::after {
  display: none;
}

.goTop {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 600;
  display: none;
}

.goTop a i,
.goTop a:visited i {
  font-size: 12px;
  line-height: inherit;
}

@media only screen and (max-width: 600px) {
  .social li {
    margin-right: 1.8rem;
  }

  .social li span,
  .social li::after {
    display: none;
  }

  .social li i {
    font-size: 2.4rem;
  }

  .bottom {
    padding-bottom: 0.6rem;
  }

  .bottom .copyright span {
    display: block;
  }

  .bottom .copyright span::after {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .logoLink {
    width: 100px;
  }

  .social li {
    margin-right: 1.2rem;
  }

  .social li i {
    font-size: 2.2rem;
  }
}
