.headerLink {
  display: inline-block;
  padding-left: 0;
  margin-right: 2rem;
}

.headerLink a {
  display: block;
  line-height: 72px;
  color: #fff;
}

.headerLink.current a {
  font-family: 'montserrat-extrabold', sans-serif;
  color: #af2b2b;
}
