.root {
  composes: section from './../Section.module.css';
  padding-top: 15rem;
  padding-bottom: 15.6rem;
  background-color: #f1f1f1;
  position: relative;
}

.intro {
  composes: intro from './../Section.module.css';
}

.introContent {
  composes: introcontent from './../Section.module.css';
}

.title {
  composes: title from './../Section.module.css';
}

.subtitle {
  composes: subtitle from './../Section.module.css';
}

.lead {
  composes: lead from './../Section.module.css';
}

.portfolio {
  composes: row from global;
  margin-top: 4.8rem;
  max-width: none;
  width: 1600px;
}

.content {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}

.masonry {
  composes: masonry from global;
}

.masonry::after {
  content: '';
  display: table;
  clear: both;
}

.masonry .grid-sizer {
  width: 400px;
}

.buttonRow {
  composes: col-six from global;
  float: none;
  margin: 0 auto;
  padding: 0 25px;
}

.buttonRow::before {
  content: '';
  display: table;
  clear: both;
}

.button {
  composes: button from './../Buttons.module.css';
  composes: primary from './../Buttons.module.css';
  display: block;
  margin: 4rem auto 0 auto;
}

/* -------------------------------------------------------------------
 * responsive:
 * works
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1700px) {
  .portfolio {
    width: 1400px;
  }

  .masonry .grid-sizer {
    width: 350px;
  }
}

@media only screen and (max-width: 1500px) {
  .portfolio {
    width: 1200px;
  }

  .masonry .grid-sizer {
    width: 400px;
  }
}

@media only screen and (max-width: 1300px) {
  .portfolio {
    width: 999px;
  }

  .masonry .grid-sizer {
    width: 333px;
  }
}

@media only screen and (max-width: 1099px) {
  .portfolio {
    width: 90%;
    max-width: 800px;
  }

  .masonry .grid-sizer {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .portfolio {
    padding: 0;
  }

  .masonry .grid-sizer {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .portfolio {
    width: auto;
  }
}
