.gh {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

.gh a,
.gh a:visited {
  text-decoration: none;
  color: #4484c2;
}

.gh a:hover {
  text-decoration: underline;
}

.card {
  position: relative;
}

.header {
  background-color: #fafafb;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-top: 6px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 11px;
}

.octocat {
  text-align: center;
  margin-bottom: 8px;
  padding-top: 10rem;
}

.title {
  font-weight: bold;
  font-size: 11px;
  color: #767676;
  vertical-align: 65%;
  padding-left: 2px;
}

.avatar img {
  width: 30px;
  height: 30px;
  float: left;
  border-radius: 3px;
}

.username {
  font-size: 13px;
  font-weight: bold;
  padding-left: 37px;
  padding-top: 1px;
  margin-bottom: 4px;
}

.hidden {
  display: none;
}

.recentlyActive {
  font-size: 11px;
  color: #999;
  padding-left: 37px;
}

.greenbox {
  width: 10px;
  height: 10px;
  background-color: #44a340;
  border-radius: 1px;
  display: inline-block;
  vertical-align: middle;
}

.recentText {
  vertical-align: middle;
  padding-left: 1px;
}

.contents {
  clear: both;
  font-size: 13px;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 9px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 9px;
}
