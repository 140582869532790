.root {
  composes: section from './../Section.module.css';
  padding-top: 15rem;
  padding-bottom: 0;
  background-color: #fff;
  position: relative;
}

.work {
  composes: row from global;
  padding-bottom: 9rem;
}

.intro {
  composes: intro from './../Section.module.css';
}

.introContent {
  composes: introcontent from './../Section.module.css';
}

.title {
  composes: title from './../Section.module.css';
}

.subtitle {
  composes: subtitle from './../Section.module.css';
}

.lead {
  composes: lead from './../Section.module.css';
}

.timeline {
  position: relative;
  text-align: left;
  margin-top: 3.6rem;
}

.timeline::before {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 2rem;
  top: 0;
}

.heading {
  composes: col-full from global;
  composes: text-center from global;
}

.left {
  composes: left from global;
  composes: col-six from global;
  composes: tab-full from global;
  padding-right: 40px;
}

.right {
  composes: right from global;
  composes: col-six from global;
  composes: tab-full from global;
  padding-left: 40px;
}

.resumeRow {
  composes: col-six from global;
  float: none;
  margin: 0 auto;
}

.resumeRow::before {
  content: '';
  display: table;
  clear: both;
}

.resumeButton {
  composes: button from './../Buttons.module.css';
  composes: primary from './../Buttons.module.css';
  display: block;
  margin: 4rem auto 0 auto;
}

@media only screen and (max-width: 800px) {
  .right {
    padding-left: 0;
  }

  .left {
    padding-right: 0;
  }

  .timeline {
    margin-top: 0;
  }
}
