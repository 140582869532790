.root {
  background-color: #fff;
}

.header {
  background-color: #0d0a0b;
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: cover;
  color: rgba(255, 255, 255, 0.5);
  min-height: 50.4rem;
  padding-bottom: 7.2rem;
  padding-top: 20.4rem;
  position: relative;
  text-align: center;
}

.header::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  background-color: #0d0a0b;
}

.header::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

.headerContent {
  composes: row from global;
  composes: narrow from global;
  font-family: 'montserrat-regular', sans-serif;
  font-size: 2rem;
  line-height: 1.8;
  position: relative;
  max-width: 900px;
  z-index: 2;
}

.title {
  color: #fff;
  position: relative;
  font-family: 'librebaskerville-bold', serif;
  font-size: 6.6rem;
  line-height: 1.227;
  margin-top: 0;
  margin-bottom: 3.6rem;
}

.caption {
  color: #fff;
  font-family: 'montserrat-regular', sans-serif;
  font-size: 2rem;
  line-height: 1.8;
  margin: 0 0 1.8rem 0;
}

.categories {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'montserrat-bold', sans-serif;
}

.category {
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
}

.category a:hover,
.category a:focus,
.category a:active {
  color: white;
}

.category::after {
  content: ', ';
}

.category:last-child::after {
  display: none;
}

.date {
  margin: 0;
}

.content {
  background-color: #fff;
  padding-top: 4.2rem;
  padding-bottom: 12rem;
  max-width: 1000px;
  padding-left: 6rem;
  padding-right: 6rem;
  margin: 0 auto;
}

.content img {
  border: 1px solid #e6e6e6;
  max-width: 100%;
  margin: 3rem 0;
}

.content h1 {
  font-size: 2.8rem;
}
