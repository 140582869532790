.section {
  composes: target-section from global;
  padding-top: 15rem;
  padding-bottom: 9rem;
  background-color: #fff;
  position: relative;
}

.intro {
  composes: row from global;
  max-width: 800px;
  padding-bottom: 1.5rem;
  position: relative;
  text-align: center;
}

.intro::after {
  display: inline-block;
  height: 1px;
  width: 240px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
}

.introContent {
  composes: col-full from global;
  composes: text-center from global;
}

.title {
  composes: h03 from global;
  font-family: 'montserrat-semibold', sans-serif;
  font-size: 2rem;
  color: #862121;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  margin-top: 0;
  margin-bottom: 0;
}

.subtitle {
  composes: h01 from global;
  font-family: 'librebaskerville-bold', serif;
  font-size: 4.8rem;
  line-height: 1.375;
  margin-top: 1rem;
}

.lead {
  font-family: 'montserrat-regular', sans-serif;
  font-size: 2rem;
  line-height: 1.8;
  margin-bottom: 3.6rem;
  color: #0d0a0b;
}

@media only screen and (max-width: 800px) {
  .title {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 4.2rem;
  }

  .lead {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.7rem;
  }

  .subtitle {
    font-size: 3.6rem;
  }
}

@media only screen and (max-width: 400px) {
  .title {
    font-size: 1.6rem;
  }

  .subtitle {
    font-size: 3.3rem;
  }
}
